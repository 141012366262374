<template>
  <div>
    <AppSpinner v-if="invoice.exists() && invoice.loading" />
    <div>
      <hr class="hr-line">
      <div class="row-top">
        <div>
          <h1 class="tittle-inv">
            Invoice
          </h1>
          <div
            v-if="invoice.client.name"
            class="client-inf"
          >
            <div
              class="invoice-id"
            >
              {{ `${invoice.invoicePrefix}-${invoice.invoiceNumber}` }}
            </div>
            <br>
            <div>
              Bill To:
            </div>
            <br>
            <div>
              {{ invoice.client.name }}
            </div>
            <div>
              {{ invoice.client.address }}
            </div>
            <br>
            <div
              class="invoice-date"
            >
              <div>
                Date:
              </div>
              <div>
                {{ date }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <!--suppress HtmlUnknownTarget -->
          <img
            class="img-logo"
            :src="invoice.organization.logo"
            alt="logo"
          >
        </div>
      </div>
    </div>
    <div
      v-if="$route.name === 'editInvoice' || $route.name === 'newInvoice'"
      class="filter-row"
    >
      <div>
        <DataFilter
          no-tag-filter
          no-description-filter
          :grouping-options="groupingOptions"
          :default-filters="invoice.filters"
          @apply="applyFilters"
        />
      </div>
    </div>
    <OopTable
      v-if="invoice.client.name"
      ref="oopTable"
      :repository="invoiceDataRepository"
      :columns="columns"
      no-auto-fetch
    >
      <template #level0-cell="scope">
        <div v-if="scope.rowIndex !== invoiceDataRepository.dataset.count() - 1">
          <template v-if="groupBy === 'task'">
            <div v-if="scope.columnIndex === 0">
              {{ scope.row.description }}
            </div>
            <div v-if="scope.columnIndex === 1">
              {{ scope.row.project }}
            </div>
            <div v-if="scope.columnIndex === 2">
              {{ formatEta(scope.row) }}
            </div>
            <div v-if="scope.columnIndex === 3">
              {{ formatDuration(scope.row) }}
            </div>
            <div v-if="scope.columnIndex === 4">
              {{ formatAmountCell(scope.row) }}
            </div>
          </template>
          <template v-if="groupBy === 'project' || groupBy === 'team'">
            <div v-if="scope.columnIndex === 0">
              {{ scope.row.description }}
            </div>
            <div v-if="scope.columnIndex === 1">
              {{ formatEta(scope.row) }}
            </div>
            <div v-if="scope.columnIndex === 2">
              {{ formatDuration(scope.row) }}
            </div>
            <div v-if="scope.columnIndex === 3">
              {{ formatAmountCell(scope.row) }}
            </div>
          </template>
          <div v-if="columns.remove && scope.columnIndex === Object.keys(columns).length -1">
            <el-button
              type="plain"
              size="mini"
              @click="removeTableRow(scope.row.uuid)"
            >
              Remove
            </el-button>
          </div>
        </div>
        <div v-else>
          <div
            v-if="scope.columnIndex === 0"
            class="summary"
          >
            Total
          </div>
          <div
            v-if="scope.columnIndex === getColumnNumber('eta')"
            class="summary"
          >
            {{ formatEta(scope.row) }}
          </div>
          <div
            v-if="scope.columnIndex === getColumnNumber('duration')"
            class="summary"
          >
            {{ formatDuration(scope.row) }}
          </div>
          <div v-if="scope.columnIndex === getColumnNumber('billedAmounts')">
            <div
              v-for="amount in scope.row.billedAmounts"
              :key="amount.currency.uuid"
              class="summary"
            >
              {{ formatAmount(amount.currency, amount.amount) }}
            </div>
          </div>
        </div>
      </template>
    </OopTable>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { CurrencyRepository } from '@timeragent/core';
import loading from '../../../../mixins/loading';

export default {
  name: 'InvoiceData',

  components: {
    AppSpinner: () => import('../../../blocks/AppSpinner.vue'),
    DataFilter: () => import('../../../blocks/DataFilter.vue'),
  },

  mixins: [loading],

  props: {
    invoice: {
      type: Object,
      required: false,
      default: () => null,
    },
    invoiceDataRepository: {
      type: Object,
      required: false,
      default: () => null,
    },
    isApply: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },

  data: () => ({
    invoiceLoading: false,
    entries: [],
    groupingOptions: [
      {
        value: 'task',
        label: 'Task',
        disabled: false,
      },
      {
        value: 'project',
        label: 'Project',
        disabled: false,
      },
      {
        value: 'team',
        label: 'Team',
        disabled: false,
      },
    ],
    defaultColumns: {
      eta: {
        title: 'Eta',
        width: '1fr',
      },
      duration: {
        title: 'Duration',
        width: '1fr',
      },
      billedAmount: {
        title: 'Billed amount',
        width: '1fr',
      },
    },
    currencyRepository: new CurrencyRepository(),
    columns: {},
  }),

  computed: {
    ...mapGetters([
      'user',
    ]),
    date() {
      return `${moment(this.invoice.date).format('DD/MM/YYYY')}`;
    },
    remove() {
      return {
        title: '',
        width: '1fr',
      };
    },
    groupBy() {
      return this.invoice.filters.groupBy;
    },
    routeName() {
      return this.$route.name;
    },
  },

  created() {
    this.setColumns();
    if (this.routeName === 'editInvoice' || this.routeName === 'newInvoice') {
      this.$set(this.columns, 'remove', this.remove);
    } else {
      delete this.columns.remove;
    }
  },

  methods: {
    setColumns() {
      if (this.groupBy === 'task') {
        this.columns = {
          description: {
            title: 'Task',
            width: '3fr',
          },
          project: {
            title: 'Project',
            width: '2fr',
          },
          ...this.defaultColumns,
        };
      }
      if (this.groupBy === 'project') {
        this.columns = {
          project: {
            title: 'Project',
            width: '3fr',
          },
          ...this.defaultColumns,
        };
      }
      if (this.groupBy === 'team') {
        this.columns = {
          team: {
            title: 'Team',
            width: '3fr',
          },
          ...this.defaultColumns,
        };
      }
      if (this.routeName === 'editInvoice' || this.routeName === 'newInvoice') {
        this.$set(this.columns, 'remove', this.remove);
      }
    },
    async applyFilters(filters) {
      if (this.routeName === 'editInvoice') {
        if (!this.isApply) {
          this.$emit('apply', true);

          return;
        }
      }
      await this.currencyRepository.many();

      const { entries, summary } = await this.invoiceDataRepository.fetchMany({
        currencyUuid: this.currencyRepository.dataset.all()[0].uuid,
        filters: {
          ...filters,
          organizationUuid: this.organizationUuid,
        },
      });

      this.entries = JSON.parse(JSON.stringify(entries));
      this.invoice.data.entries = this.entries;
      this.invoice.data.summary = summary;
      this.invoice.filters = Object.assign(filters, { organizationUuid: this.organizationUuid });

      if (entries.length) {
        entries.push(summary);
      }
      this.invoiceDataRepository.dataset = this.invoiceDataRepository.fromArray(entries);
      this.invoiceDataRepository.loading = false;
      this.setColumns();
    },
    removeTableRow(uuid) {
      this.invoiceDataRepository.dataset = this.invoiceDataRepository.dataset.filter(entry => entry.uuid !== uuid);
      this.invoice.data.entries = this.invoice.data.entries.filter(entry => entry.uuid !== uuid);
      let duration = 0;
      let eta = 0;
      let billedAmount = 0;

      // Filter for get entries without summary
      this.invoiceDataRepository.dataset.filter(entry => entry.uuid).each(entry => {
        duration += entry.duration;
        eta += entry.eta;
        billedAmount += entry.billedAmount;
      });
      const lastEntry = this.invoiceDataRepository.dataset.last();

      lastEntry.duration = duration;
      lastEntry.eta = eta;
      lastEntry.billedAmounts[0].amount = billedAmount;
      this.removeEntryFromInvoice(uuid, duration, eta, billedAmount);
    },
    removeEntryFromInvoice(entryUuid, duration, eta, billedAmount) {
      this.invoice.data.entries = this.invoice.data.entries.filter(entry => entry.uuid !== entryUuid);
      const { summary } = this.invoice.data;

      summary.duration = duration;
      summary.eta = eta;
      summary.billedAmounts[0].amount = billedAmount;
    },
    getColumnNumber(columnName) {
      if (this.groupBy === 'task') {
        if (columnName === 'eta') return 2;
        if (columnName === 'duration') return 3;
        if (columnName === 'billedAmounts') return 4;
      }
      if (this.groupBy === 'project' || this.groupBy === 'team') {
        if (columnName === 'eta') return 1;
        if (columnName === 'duration') return 2;
        if (columnName === 'billedAmounts') return 3;
      }

      return 0;
    },
    // Formatters
    formatEta(row) {
      return moment.duration(+row.eta, 'ms').format();
    },
    formatDuration(row) {
      return moment.duration(+row.duration, 'ms').format();
    },
    formatAmountCell(row) {
      return this.formatAmount(row.currency, row.billedAmount);
    },
    formatAmount(currency, amount) {
      return `${currency.symbol} ${Math.floor(
        (amount + 0.00001) * (10 ** currency.decimalDigits),
      ) / (10 ** currency.decimalDigits)}`;
    },
  },
};
</script>

<style
  lang="stylus"
  rel="stylesheet/stylus"
  scoped
>
  .img-logo {
    width: 200px;
    margin: 0 0 0 20px;
  }

  .hr-line {
    background-color: #3d8eca;
    height: 10px;
    border: none;
    margin-top: 20px;
  }

  .row-top {
    display: grid;
    grid-template-columns: 75% auto;
    justify-content: space-between;
    color: #6A717C;
  }
  .filter-row {
    display: grid;
    grid-template: auto / auto auto;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .tittle-inv {
    color: #3D8ECA;
    font-size: 30px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .client-inf {
    padding-left: 10px;
  }

  .page-title {
    padding: 0;
    font-size: 28px;
    line-height: 33px;
    display: block;
    margin: 0 0 25px;
    height: 40px;
    word-break: break-all;
  }

  .invoice-id {
    font-size: 17px;
  }

  .invoice-date {
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    color: #3D8ECA;
    margin-bottom: 20px;
  }

  .table {
    margin-top: 20px;
  }

  .summary {
    font-weight: 400;
    font-size: 16px;
    color: #67c23a;
  }

</style>
